@import 'fonts';
@import 'variables';
@import 'utils';

@import 'components/steppers';
@import 'components/async-content';
@import 'components/animated-checkmark';
@import 'components/daterange-input';
@import 'components/upload-button';
@import 'pages/stepform';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'SofiaPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

.app-bar {
  .logo {
    cursor: pointer;
    max-width: 100px;
    max-height: 100px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .title {
    flex-grow: 1;
    margin-left: 16px;
    font-weight: 900;
  }
}

:root {
  --amplify-primary-color: #102d5e;
  --amplify-primary-tint: #102d5e;
  --amplify-primary-shade: #102d5e;
}

.refresh-loader {
  position: absolute;
  left: 50%;
  top: 50%;
}

.reset-search {
  display: inline-block !important;
}

.link {
  font-weight: 700;
  font-size: 1.1em;
  text-decoration: underline;
  color: #102d5e;
  cursor: pointer;
}

.no-reservations-found {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
