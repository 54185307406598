@import '../variables';

.react-datepicker-popper {
  z-index: 1000 !important;
}

.react-datepicker {
  font-family: 'SofiaPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  border: none;
  color: $black;
}

.react-datepicker__day {
  border-radius: 50%;

  &:hover {
    background-color: #d0d0d0;
    border-radius: 50%;
  }
}

.react-datepicker__day--today {
  background-color: #d0d0d0;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #aaadb3 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 40px;
  line-height: 40px;
}

.react-datepicker__day--keyboard-selected {
  background-color: #aaadb3 !important;
}

.react-datepicker__time-container {
  .react-datepicker__time-list-item--selected {
    background-color: #aaadb3 !important;
  }
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  text-transform: capitalize;
}
